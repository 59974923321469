import { AmountDifferenceParams } from '../../shared/components/amount-difference/amount-difference.component';
import { Entity } from '../entities/entities.model';
import { ProgramSelect } from '../../../../../api/src/program/utils/query.utils';
import { TacticSelect } from '../../../../../api/src/tactic/utils/query.utils';
import { InvoiceSelect } from '../../../../../api/src/invoice/utils/query.utils';
import { PlanSelect } from '../../../../../api/src/plan/utils/query.utils';
import { Aggregate, Filter, Filter as FilterCondition } from '../../../../../api/src/export/dtos/export.dto';
import { BudgetCache, Entities, ValueType } from '../global/global.model';
import { FilterListConfig, FilterOption, FilterType } from '../entities/filter/filter.model';
import { ChartCalendarLevel } from 'gantt-schedule-timeline-calendar';
import { LogicalConjunction } from '../../../../../api/src/_core/models/math-operations';
import { MeasurementAggregateType } from '../entities/measurement/measurement.model';

export type TablePage = string;

/**
 * Table Row model
 * A TableRow is a wrapper for a data object with properties
 * needed to render a mat-table row.
 * Pretty sure we need to remove this in favor of TableItem
 *
 */
export type TableRow<T> = T & {
	id: string;
	parentId?: string;
	expanded?: boolean;
	rowType: string;
};

/**
 * Table Item model
 * A TableRow is a wrapper for a data object with properties
 * needed to render a mat-table row.
 */
export type TableItem<T> = T & {
	parentId: string;
	type: string;
	level: number;
	deeplink?: string;
	children?: TableCollection<T>;
	budgetCache?: BudgetCache;
};

export interface TableItemDataProperty {
	difference?: AmountDifferenceParams;
	tooltip?: string;
	value: string;
	rawValue?: any;
}

/**
 * Collectin Meta model
 * A collection of the pagination meta data for a collection
 */
export interface CollectionMeta {
	limit: number;
	page?: number;
	totalResults: number;
	totalPages: number;
	type: string;
	paginationEndpoint: string;
}

/**
 * Table collection model
 * A collection of TableItems with collection meta data included around the pagination.
 */
export type TableCollection<T> = CollectionMeta & {
	items: TableItem<T>[];
	budgetCache: BudgetCache;
};

/**
 * Table Settings model
 * Table specific filter settings.
 */
export interface TableSettings {
	section: TablePage;
	sort: SortCollection;
	columns: ColumnCollection[];
	activeColumns: string[];
	measurementTypes: MeasurementAggregateType[];
	hierarchy: string[];
	expandedRows: string[];
	zoomLevels: ZoomLevel[];
	zoomLevel: ZoomLevel;
	columnWidths?: any[];
	contractedRows?: boolean;
	initScrollTop?: boolean;
}

/**
 * Calendar Settings model
 * Calendar specific filter settings.
 */
export interface CalendarSettings extends TableSettings {
	innerHeight: number;
	flatNames?: boolean;
	activeCalendarColumns?: string[];
	calculatedZoomMode?: boolean;
	calendarEditMode?: boolean; // Triggers editable features on the calendar
	exportToImage?: boolean; // Triggers export to image on the calendar
	items: {
		showBudgetDistributions?: boolean; // Show budget distributions on the calendar vs just the parent.
		slotFields?: Column[];
		activeSlotFields?: any;
		activeTooltipFields?: any;
		test?: any;
	};
	header: {
		enabled?: boolean;
	};
	calendarDateRange: {
		start: string;
		end: string;
	};
	// Used for inline calendar to add a filter select on the module.
	filters?: FilterListConfig;
	layers?: {
		enabled: boolean;
		items?: any[];
		overlaysHeight?: number;
		pinnedToTop?: boolean;
		layersExpandedRow?: boolean;
	};
	scroll?: {
		broadcastHorizontal?: boolean;
		broadcastVertical?: boolean;
		hideRightScrollbar?: boolean;
		hideBottomScrollbar?: boolean;
	};
	calendarLevels: ChartCalendarLevel[];
}

export interface CalendarBasicSettings extends CalendarSettings {
	toolbar?: {
		batchCreate?: boolean;
		zoomLevel?: boolean;
		dateRange?: boolean;
		columns?: boolean;
		menu?: {
			export?: boolean;
			fullscreen?: boolean;
		};
		// Not implemented yet.
		// inlineFilters?: {
		// 	left?: {
		// 		includeEndpoint?: string;
		// 		includeIds?: string[];
		// 	};
		// 	right?: {
		// 		includeEndpoint?: string;
		// 		includeIds?: string[];
		// 	};
		// };
	};
	messages?: {
		empty?: string; // Message to show when there are no items in the calendar.
	};
	isFullScreen?: boolean;
	autoHeight?: boolean; // Set the calendar to auto height based on the number of items.
	hideSlotFields?: boolean; // Hide the slot fields on the calendar.
}

/**
 * This is an abstraction of the Gantt plugin's ChartCalendarLevel.  It allows us to add a name, height, and other config
 * to the level.
 */
export interface CalendarLevel {
	id: string;
	name: string;
	height: number;
	minZoom?: number; // The minimum zoom level for this level.  Helps with height calculations.
	maxZoom?: number; // The maximum zoom level for this level.  Helps with height calculations.
	level?: ChartCalendarLevel;
}

/**
 * Sort Collection model
 * The complete table sorting object.
 */
export interface SortCollection {
	outer?: SortDescription;
	level1?: SortDescription;
	level2?: SortDescription;
	level3?: SortDescription;
}

/**
 * Sort Description model
 * A description of how a property should be sorted.
 */
export interface SortDescription {
	property: string;
	strategy: SortStrategy;
	advanced?: {};
}

/**
 * Which direction to sort in.
 */
export enum SortStrategy {
	ASC = 'ASC',
	DESC = 'DESC'
}

/**
 * The zoom level select options of the calendar
 */
export interface ZoomLevel extends Entity {
	value: number;
}

/**
 * Defines a collection of columns for the calendar
 */
export interface ColumnCollection extends Entity {
	items: Column[];
}

/**
 * Column model
 * A column is a vertical row of information in a table.
 * Defines lots of ui and export settings for a property as well.
 */
export interface Column {
	id: string;
	name: string;
	path: string; // Dot notation to the correct property
	exportPath?: string; // Optional path for the API to use when exporting
	type: ValueType;

	/** Helps to group columns into categories for users to select from. */
	category: string;

	entityTypes: Entities[]; // Which entities have values for this column.  Also directs whether the column can be editable on certain rows.
	dependencies?: PlanSelect[] | ProgramSelect[] | TacticSelect[] | InvoiceSelect[]; // Describes the top level object keys this filter depends on. (eg. ['name', 'budgetCache', 'etc'])
	hideFromMenu?: boolean;
	sortable?: boolean; // Whether the column is sortable or not.
	sortStrategy?: SortStrategy;
	filter?: Filter;
	aggregate?: Aggregate;
	button?: {
		label?: string;
		icon?: string;

		/** The action type that will trigger when clicking the button. */
		action?: string;
		actionParams?: any;

		type?: 'mat-flat-button' | 'mat-mini-fab';
		size?: 'large' | 'small' | 'extra-small';
		color?: string;
		tooltip?: string;
	};
	editable?: {
		/** Whether the column is editable or not. */
		enabled?: boolean;

		/** The type of editor to use. */
		type?: 'currency' | 'program-budget' | 'uneditable' | 'notes' | 'quick-edit-dialog' | 'program-quick-edit' | FilterType;

		/** Useful if you want to make sure a different editor pops up. */
		idAlias?: string;

		options?: FilterOption[];

		/** Pull options in from a settings object. */
		optionsFromSettings?: string;

		/** Allows you to override the path of the column for saving a value. */
		pathOverride?: string;

		filterId?: string;

		/** Should we just load in a fresh entity on closing from the API? */
		dismissWithUpdatedEntity?: boolean;

		/** The size of the modal to use. */
		modalSize?: 'sm' | 'md' | 'lg';

		/** This message will show if the field is uneditable in some context. Typically only used in places that are already uneditable. */
		uneditableReason?: string;

		/** Hide the snack bar pop up */
		hideSnackBar?: boolean;

		/** Changes the editable settings for different row types */
		rowTypeOverrides?: (Column['editable'] & { rowType: string })[];
	};

	/**
	 * @deprecated
	 * Use visibilityConditions instead
	 */
	visibilityCondition?: FilterCondition;
	visibilityConditions?: { operator: LogicalConjunction; filterConditions: FilterCondition[] }; // Multiple conditions that must be met for the filter to be visible

	// // A Filter condition for columns from the exporter.  Only used for some types.
	// filter?: {
	// 	path?: string;
	// 	condition: Conditio;
	// 	value: any;
	// };

	// // A Aggregate condition for columns from the exporter. Only used for some types.
	// aggregate?: {
	// 	path?: string;
	// 	function: AggregateFunction;
	// }

	// Any type specific extra settings can be thrown in here.
	extra?: {
		hideHeaderLabel?: boolean; // Hides the header for the column
		dynamic?: boolean; // Denotes that the column was made dynamically from settings options.
		width?: number; // Sets the default width of the column
		mask?: string; // Runs the value through a mask
		tooltip?: boolean; // Shows a tooltip on hover
		tooltipText?: string; // A tooltip text to show on hover
		alternativeName?: string; // An alternative name to show in the column header
		[key: string]: any; // Plenty of other options
	};
}

/**
 * Dynamic columns are entities that describe a set of columns that need
 * to be added to the column collections based on settings options that come from the API.
 */
export interface DynamicColumn {
	id: string;
	settingsKey: string; // The key with options to populate the columns.
	collectionName: string; // The name of the collection to add the columns to. eg. 'overall', 'programs' etc.

	// The column entity to add to the column collection.  It will be filled in by the options.
	// You can also use simple merge tags for name and id for the name value.
	column: Column;

	maskTypePath?: string; // The path to where the mask type is set on the option.
}

/**
 * Defines the event object when clicking a column.
 */
export interface ColumnClickEvent {
	columnName: string;
	column: Column;
	row: TableRow<any>;
}

export interface ColumnItemClickEvent {
	column: Column;
	row: TableRow<any>;
}

export interface ItemClickEvent {
	item: any;
	row: TableRow<any>;
}

export interface ItemDateChangeEvent<T> {
	item: T;
	start: string;
	end: string;
}

/**
 * The default sorting options.
 */
export const DefaultSortCollection = {
	outer: {
		property: 'name',
		strategy: 'ASC'
	},
	level1: {
		property: 'name',
		strategy: 'ASC'
	},
	level2: {
		property: 'name',
		strategy: 'ASC'
	},
	level3: {
		property: 'name',
		strategy: 'ASC'
	}
};

export const zoomLevels = [
	{
		id: 'all',
		name: 'All',
		value: undefined
	},
	{
		id: 'days',
		name: 'Days',
		value: 20
	},
	{
		id: 'days-compact',
		name: 'Days (Compact)',
		value: 22
	},
	{
		id: 'weeks',
		name: 'Weeks',
		value: 23
	},
	{
		id: 'weeks-compact',
		name: 'Weeks (Compact)',
		value: 24
	},
	{
		id: 'months',
		name: 'Months',
		value: 26
	}
];

export const defaultZoomLevel = zoomLevels[0];

export const levelHeights = [55, 40, 32, 24];
export const levelHeightsWithPadding = [55, 48, 48, 48];
